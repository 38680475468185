import { Link } from '@esg-monitor/core';
import { useNavigation } from '@remix-run/react';

export default function Index() {
  const navigation = useNavigation();
  const isLoading = useNavigation().state === 'loading';
  return (
    <div className="flex h-full flex-col items-center justify-center text-center py-12">
      {isLoading ? (
        <div className="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary/60 text-primary-foreground shadow h-10 px-8 rounded-lg">
          Loading...
        </div>
      ) : (
        <Link
          to={`/login`}
          className="rounded-lg"
          variant={'default'}
          size={'lg'}
        >
          Continue to Sign-in
        </Link>
      )}
    </div>
  );
}
